import React from 'react';
import StatisticalTab from './components/statisticalTab';
import { observer } from 'mobx-react';
import {withRouter} from 'react-router-dom'
import SearchForm from './components/searchForm';
import moment from 'moment'
import './index.less';
const WrapperView = Loader.loadBusinessComponent('SystemWrapper');


@withRouter
@Decorator.businessProvider('logStatistic')
@observer
class StatisticalView extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      list: [],
      loading:false,
      total: 0,
      timeActive: 7,
      queryActiveUserNumber:[],
      queryDataResourceStatistics:[],
      queryFunctionUsageStatistics:[],
      queryModuleUsageStatistics:[]
    }
  }
  componentDidMount() {
    const {logStatistic} =this.props
    logStatistic.initData()
    this.setTimeRange(7)
  }

//获取统计量
  getStatics = (searchData,logList) => {
    let optionsFunction = Object.assign({},searchData,{limit:3})
    let optionsModule = Object.assign({},searchData,{limit:0})
    // this.setState({
    //   loading:true
    // })
    Service.logStatistics.queryActiveUserNumber(searchData).then(res => {
      const queryActiveUserNumber = res.data ? res.data:[]
      this.setState({ queryActiveUserNumber })
    })
    Service.logStatistics.queryDataResourceStatistics(searchData).then(res => {
      const queryDataResourceStatistics = res.data ? res.data:[]
      this.setState({ queryDataResourceStatistics })
    })
    Service.logStatistics.queryFunctionUsageStatistics(optionsFunction).then(res => {
      const queryFunctionUsageStatistics = res.data ? res.data:[]
      this.setState({ queryFunctionUsageStatistics })
    })
    Service.logStatistics.queryModuleUsageStatistics(optionsModule).then(res => {
      const queryModuleUsageStatistics = res.data ? res.data:[]
      this.setState({ queryModuleUsageStatistics })
    })
    this.setState({
      list: logList,
      total: logList.length,
      // loading:false
    })
    // Promise.all([
    //   Service.logStatistics.queryActiveUserNumber(searchData),
    //   Service.logStatistics.queryDataResourceStatistics(searchData),
    //   Service.logStatistics.queryFunctionUsageStatistics(optionsFunction),
    //   Service.logStatistics.queryModuleUsageStatistics(optionsModule)
    // ]).then(([res1, res2, res3, res4]) => {
    //   const queryActiveUserNumber = res1.data ? res1.data:[]
    //   const queryDataResourceStatistics = res2.data ? res2.data : []
    //   const queryFunctionUsageStatistics = res3.data ? res3.data : []
    //   const queryModuleUsageStatistics = res4.data ? res4.data : []
    //   this.setState({
    //     queryActiveUserNumber,
    //     queryDataResourceStatistics,
    //     queryFunctionUsageStatistics,
    //     queryModuleUsageStatistics,
    //     list: logList,
    //     total: logList.length,
    //     loading:false
    //   })
    // })
  }
  //获取列表
  getList() {
    const { logStatistic } = this.props
    const { searchData } = logStatistic
    Service.logStatistics.queryList(searchData).then(res => {
      this.getStatics(searchData,res.data)
    })
  }
  // 修改store的searchData
  updateStoreSearchData = (data) => {
    const {logStatistic} = this.props;
    logStatistic.updateSearchData(data);
    this.getList()
  }
  // 选时控件change
  timeChange = (type,value) => {
    const newDate = {}
    if (type === 'startTime') {
      // newDate.startTime = moment(new Date(value)).format(DATE_FORMAT)
      newDate.startTime = new Date(value)*1
    } else {
      // newDate.endTime = moment(new Date(value)).format(DATE_FORMAT)
      newDate.endTime = new Date(value)*1
    }
    this.updateStoreSearchData(newDate);
    this.setState({
      timeActive: null
    })
  }


  // 切换统计时间
  setTimeRange = (value) => {
    const endTime = +new Date();
    const startTime = (moment(endTime).subtract(value, 'days')).valueOf();
    this.updateStoreSearchData({
      startTime: startTime,
      endTime: endTime
    })
    this.setState({
      timeActive: value
    })
  }
  //切换统计类型
  searchLogByCenterIds = (searchData) => {
    // this.setTimeRange(this.state.timeActive)
    this.updateStoreSearchData(searchData)
    // this.getList()
  }

  render() {
    const { 
      list,
      total,
      timeActive ,
      loading,
      queryActiveUserNumber,
      queryDataResourceStatistics,
      queryFunctionUsageStatistics,
      queryModuleUsageStatistics
    } = this.state
    return (
      <WrapperView name = '数据统计' width='1200px'>
        <div className='logstatistical-view'> 
          <div className = 'logstatistical-title table-setting-title' >
            <SearchForm 
              doSearch = {() => this.getList()} 
              className = 'logstatistical-search-view' 
              setTimeRange={this.setTimeRange} 
              searchLogByCenterIds={(searchData) => this.searchLogByCenterIds(searchData)} 
              timeActive = {timeActive}
              timeChange = {(type,value) => {this.timeChange(type,value)}}
              queryActiveUserNumber={queryActiveUserNumber}
              queryDataResourceStatistics={queryDataResourceStatistics}
              queryFunctionUsageStatistics={queryFunctionUsageStatistics}
              queryModuleUsageStatistics={queryModuleUsageStatistics}
            />
          </div>
          <div className="statistical-list">
            <StatisticalTab
							loading={loading}
              dataSource={list} 
              handleTableChange={this.handleTableChange}
              total={total}
              scroll={{ y: '100%' }}
            />
          </div>
        </div>
      </WrapperView>
    )
  }
}

export default StatisticalView