import React from 'react';

const Table=Loader.loadBaseComponent('Table');

function myorter(a,b,name){
  if(a[name] === '-' ){
    a[name]=0
  }
  if(b[name] === '-'){
    b[name]=0
  }
  return a[name] - b[name]
}
function percent (text) {
  if(text!=='-'){
    if((text*100+'%').length<=5){
      // console.log(text, 1111);
      text=text*100+'%'
    }else{
      text=(text+'').replace('0.0','').replace('0.','')+'%'
    }
  }
  return text
}
const columns = [
  [
    { title: '模块名称', width: '25%', dataIndex: 'moduleName',key:'moduleName'},
    { title: '入口点击次数', sorter: (a, b) => myorter(a,b,'moduleVisitorNum'), width: '25%', dataIndex: 'moduleVisitorNum',key: 'moduleVisitorNum' },
    { title: '登录人数', sorter: (a, b) => myorter(a,b,'sysLandingUserNum'), width: '25%', dataIndex: 'sysLandingUserNum',key: 'sysLandingUserNum' },
    { title: '到达率', sorter: (a, b) => myorter(a,b,'moduleAccessRate'), width: '25%', dataIndex: 'moduleAccessRate',key: 'moduleAccessRate' , render: (text) => percent(text)
    }    
  ],
  [
    { title: '模块名称', width: '20%', dataIndex: 'name',key: 'name' },
    { title: '入口点击次数', sorter: (a, b) => myorter(a,b,'operationNum'),width: '20%', dataIndex: 'operationNum',key: 'operationNum'},
    { title: '入口点击人数', sorter: (a, b) => myorter(a,b,'operationUserNum'), width: '20%', dataIndex: 'operationUserNum',key: 'operationUserNum' },
    { title: '平均点击次数', sorter: (a, b) => myorter(a,b,'avgOperationNum'), width: '20%', dataIndex: 'avgOperationNum',key: 'avgOperationNum' },
    { title: '平均点击率', sorter: (a, b) => myorter(a,b,'rate'), width: '20%', dataIndex: 'rate',key: 'rate', render: (text) => percent(text) }    
  ],
  [
    { title: '模块名称', width: '20%', dataIndex: 'name',key: 'name' },
    { title: '搜索次数', sorter: (a, b) => myorter(a,b,'operationNum'), width: '20%', dataIndex: 'operationNum',key: 'operationNum' },
    { title: '使用人数', sorter: (a, b) => myorter(a,b,'operationUserNum'), width: '20%', dataIndex: 'operationUserNum',key: 'operationUserNum' },
    { title: '平均次数', sorter: (a, b) => myorter(a,b,'avgOperationNum'), width: '20%', dataIndex: 'avgOperationNum',key: 'avgOperationNum' },
    { title: '比重', sorter: (a, b) => myorter(a,b,'rate'), width: '20%', dataIndex: 'rate',key: 'rate', render: (text) => percent(text)}
  ],
  [
    { title: '模块名称', width: '20%', dataIndex: 'name',key: 'name' },
    { title: '搜索次数', sorter: (a, b) => myorter(a,b,'operationNum'), width: '20%', dataIndex: 'operationNum',key: 'operationNum' },
    { title: '使用人数', sorter: (a, b) => myorter(a,b,'operationUserNum'), width: '20%', dataIndex: 'operationUserNum',key: 'operationUserNum' },
    { title: '平均次数', sorter: (a, b) => myorter(a,b,'avgOperationNum'), width: '20%', dataIndex: 'avgOperationNum',key: 'avgOperationNum' },
    { title: '比重', sorter: (a, b) => myorter(a,b,'rate'), width: '20%', dataIndex: 'rate',key: 'rate', render: (text) => percent(text) }
  ],
  [
    { title: '功能模块', width: '20%', dataIndex: 'moduleName',key: 'moduleName' },
    { title: '操作功能', width: '20%', dataIndex: 'functionName',key: 'functionName' },
    { title: '操作用户数', sorter: (a, b) => myorter(a,b,'userNum'), width: '20%', dataIndex: 'userNum',key: 'userNum' },
    { title: '操作次数', sorter: (a, b) => myorter(a,b,'operationNum'), width: '20%', dataIndex: 'operationNum',key: 'operationNum' },
    { title: '平均点击率', sorter: (a, b) => myorter(a,b,'averageRate'), width: '20%', dataIndex: 'averageRate',key: 'averageRate', render: (text) => percent(text) }
  ],
]

const ListView = ({ dataSource, columnType=4, handleTableChange ,loading, total,onChange , ...props}) => {
  return (
    <div className='statistical-table-view'>
      <Table
        columns={columns[columnType]}
        dataSource={dataSource}
        loading={loading}
        rowKey={false}
        {...props}
        />
    </div>
  )
}

export default ListView;