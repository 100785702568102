import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { withRouter } from 'react-router-dom';
import { Button,Select } from 'antd';
import Config from 'src/service/config';

const LogStatistic = Loader.loadBusinessComponent(
  "Card",
  "CommunityChartCard"
);

const Option = Select.Option;
const DateRangePicker = Loader.loadBaseComponent('RangePicker');
const { api } = Config;
const timeSearchBtns = [{
	label: '7日',
	value: 7
}, {
	label: '15日',
	value: 15
}, {
	label: '30日',
	value: 30
}, ]

const typeSearchBtns = [{
	label: '模块到达率',
	value: 1
}, {
	label: '模块使用率',
	value: 2
}, {
	label: '人脸、人体图库分析',
	value: 3
}, { 
	label: '搜图方式分析',
	value: 4
}, ]
const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

@withRouter
@Decorator.businessProvider('logStatistic','operation')
@observer

class SearchForm extends React.Component {
	constructor(props) {
    super(props);
    this.state={
      operationCenterList:[],
      list:[],
      dateBegin: moment(this.props.logStatistic.searchData.startTime),
			dateEnd:moment(this.props.logStatistic.searchData.endTime),
    }
  }
  componentDidMount = () => {
    Service.operation.queryOperationCenters().then(res => {
      let operationCenterList=res.data?res.data.list:[]
      operationCenterList.unshift({
          id:null,
          operationCenterName:'全部'
        }
      )
      this.setState({
        operationCenterList:res.data?res.data.list:[]
      })
    })
  }

  download = () => {
    const {searchData}=this.props.logStatistic
    Service.logStatistics.exportLogs(searchData).then(res => {
      
    })
  }
  searchLog = (e,type) => {
    const {searchLogByCenterIds}=this.props
    let centerIds=e?[e]:null
    searchLogByCenterIds&&searchLogByCenterIds({centerIds})
   
  }
	render(){
    const { logStatistic,
      className,
      setTimeRange,
      timeChange,
      timeActive,
      queryActiveUserNumber,
      queryDataResourceStatistics,
      queryFunctionUsageStatistics,
      queryModuleUsageStatistics
    } = this.props
    const { searchData } = logStatistic
    const {operationCenterList}=this.state
    let showStatistic=parseInt(queryActiveUserNumber.totalUser||queryActiveUserNumber.activeUserByDay||queryActiveUserNumber.activeUserByMonth)?true:false
    let funcStatistic=queryModuleUsageStatistics.length>0?{
      funcOne:{ name:queryModuleUsageStatistics[0]?queryModuleUsageStatistics[0].moduleName:'' , value:queryModuleUsageStatistics[0]?queryModuleUsageStatistics[0].operationNum:0},
      funcTwo:{ name:queryModuleUsageStatistics[1]?queryModuleUsageStatistics[1].moduleName:'' , value:queryModuleUsageStatistics[1]?queryModuleUsageStatistics[1].operationNum:0},
      funcThree:{ name:queryModuleUsageStatistics[2]?queryModuleUsageStatistics[2].moduleName:'' , value:queryModuleUsageStatistics[2]?queryModuleUsageStatistics[2].operationNum:0}
    }:null

    let moduleStatistic=queryFunctionUsageStatistics.length>0?{
      moduleOne:{ name:queryFunctionUsageStatistics[0]?queryFunctionUsageStatistics[0].functionName:'' , value:queryFunctionUsageStatistics[0]?queryFunctionUsageStatistics[0].operationNum:0},
      moduleTwo:{ name:queryFunctionUsageStatistics[1]?queryFunctionUsageStatistics[1].functionName:'' , value:queryFunctionUsageStatistics[1]?queryFunctionUsageStatistics[1].operationNum:0},
      moduleThree:{ name:queryFunctionUsageStatistics[2]?queryFunctionUsageStatistics[2].functionName:'' , value:queryFunctionUsageStatistics[2]?queryFunctionUsageStatistics[2].operationNum:0}
    }:null

    let otherStatistic= queryDataResourceStatistics.length>0?{
      moduleOne:{ name:queryDataResourceStatistics[0].moduleName , value:queryDataResourceStatistics[0].operationNum ? queryDataResourceStatistics[0].operationNum:0},
      moduleTwo:{ name:queryDataResourceStatistics[1].moduleName , value:queryDataResourceStatistics[1].operationNum ? queryDataResourceStatistics[1].operationNum:0},
      moduleThree:{ name:queryDataResourceStatistics[2].moduleName , value:queryDataResourceStatistics[2].operationNum ? queryDataResourceStatistics[2].operationNum:0},
      moduleFour:{ name:queryDataResourceStatistics[3].moduleName , value:queryDataResourceStatistics[3].operationNum ? queryDataResourceStatistics[3].operationNum:0}
    }:null

		return (
			<div className={className}>
        <div className="log-search">
          <div className="time-view search-part">
            <div className="search-title">时间：</div>
            <div className="search-box time-search-box">
              <div className='time-btns'>
                { timeSearchBtns.map(v => (
                    <Button
                      key={v.value}
                      className={timeActive === v.value ? 'active' : ''}
                      onClick={() => setTimeRange(v.value)}
                    >
                      {v.label}
                    </Button>
                  ))
                }
              </div>
              <DateRangePicker
                allowClear={false}
                className='date-range'
                startTime={moment(searchData.startTime)}
                endTime={moment(searchData.endTime)}
                maxDate={true}
                onChange={timeChange}
              />
            </div>
          </div>
          <div className="type-view search-part">
            <Select placeholder="应用系统" onChange={(e) => this.searchLog(e,'system')}>
              {operationCenterList.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.operationCenterName}
                </Option>
              ))}
            </Select>
          </div>
          <div className="btn-box">
            <Button 
              type="primary" 
              className='download' 
              icon="download"
              onClick={this.download}
            >
              下载表格
            </Button>
        </div>
        </div>
          <div className='log-statistic-card'>
         { showStatistic&&<div className='user-num-statistic'>
              <span>
                用户量：{queryActiveUserNumber.totalUser}
              </span>
              <span>
                日活用户：{queryActiveUserNumber.activeUserByDay}
              </span>
              <span>
                月活用户：{queryActiveUserNumber.activeUserByMonth}
              </span>
            </div>}
           { funcStatistic &&<LogStatistic
              title="功能模块操作量TOP3"
              startRadius={"0%"}
              endRadius={"100%"}
              filterData={[
                {
                  name: funcStatistic.funcOne.name,
                  value: funcStatistic.funcOne.value ,
                },
                {
                  name: funcStatistic.funcTwo.name,
                  value: funcStatistic.funcTwo.value,
                },
                {
                  name: funcStatistic.funcThree.name,
                  value: funcStatistic.funcThree.value ,
                }
              ]}
              data={[
                funcStatistic.funcOne.value,funcStatistic.funcTwo.value,funcStatistic.funcThree.value
              ]}
              myColor={["#FFAA00", "#D8DCE3","#8899BB"]}
            />}
           { moduleStatistic&&<LogStatistic
              startRadius={"0%"}
              endRadius={"100%"}
              title="功能操作量TOP3"
              filterData={[
                {
                  name: moduleStatistic.moduleOne.name,
                  value: moduleStatistic.moduleOne.value,
                },
                {
                  name:moduleStatistic.moduleTwo.name,
                  value: moduleStatistic.moduleTwo.value,
                },
                {
                  name: moduleStatistic.moduleThree.name,
                  value: moduleStatistic.moduleThree.value,
                }
              ]}
              data={[
                moduleStatistic.moduleOne.value,moduleStatistic.moduleTwo.value,moduleStatistic.moduleThree.value
              ]}
              myColor={["#4CC4F8", "#8899BB", "#D8DCE3"]}
            />}
            {otherStatistic&&<LogStatistic
              title="图库查询量"
              startRadius={"0%"}
              endRadius={"100%"}
              className='library-statistic'
              filterData={[
                {
                  name: otherStatistic.moduleOne.name,
                  value: otherStatistic.moduleOne.value,
                },
                {
                  name: otherStatistic.moduleTwo.name,
                  value: otherStatistic.moduleTwo.value,
                },
                {
                  name: otherStatistic.moduleThree.name,
                  value: otherStatistic.moduleThree.value,
                },
                {
                  name: otherStatistic.moduleFour.name,
                  value:otherStatistic.moduleFour.value,
                },
              ]}
              data={[
                otherStatistic.moduleOne.value,otherStatistic.moduleTwo.value,otherStatistic.moduleThree.value,otherStatistic.moduleFour.value
              ]}
              myColor={["#FFAA00", "#D8DCE3","#8899BB", " #4CC4F8"]}
            />}
          </div>
			</div>
		)
	}
}


export default SearchForm;
