(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("moment"), require("antd"), require("mobxReact"), require("ReactRouterDOM"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("logStatistical", ["React", "moment", "antd", "mobxReact", "ReactRouterDOM", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["logStatistical"] = factory(require("React"), require("moment"), require("antd"), require("mobxReact"), require("ReactRouterDOM"), require("mobx"));
	else
		root["logStatistical"] = factory(root["React"], root["moment"], root["antd"], root["mobxReact"], root["ReactRouterDOM"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 